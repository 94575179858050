import React from "react";

// COMPONENT IMPORTS
import Navbar from "./components/Navbar";
import TwoColumnLayout from "./components/TwoColumnLayout";
import OurStory from "./components/OurStory";
import Foresight from "./components/Foresight";
import Store from "./components/Store";
import Utility from "./components/Utility";
import LineUp from "./components/LineUp";
import Gaming from "./components/Gaming";
import Partners from "./components/Partners";
import Footer from "./components/Footer";
import FAQSection from "./components/FAQ";
import Distribution from "./components/Distribution";

// THEME IMPORTS
import { ChakraProvider, Box } from "@chakra-ui/react";
import "./styles.css";
import "./fonts/Hate Agent.otf";
import Team from "./components/Team";

const App = () => {
  return (
    <ChakraProvider>
      <div className="App">
        <Navbar />
        <Box paddingTop={"50"}></Box>
        <TwoColumnLayout imageDir={"images/ducks/Legendary Duketz.png"}>
          <OurStory />
        </TwoColumnLayout>
        <TwoColumnLayout imageDir={"images/ducks/Proper Duck.png"}>
          <Foresight />
        </TwoColumnLayout>
        <TwoColumnLayout imageDir={"images/ducks/Buu Outfit 2.png"}>
          <Utility />
        </TwoColumnLayout>
        <TwoColumnLayout imageDir={"images/ducks/Legendary 900 1.png"}>
          <Store />
        </TwoColumnLayout>
        <TwoColumnLayout imageDir={"images/ducks/Piccalo Outfit.png"}>
          <LineUp />
        </TwoColumnLayout>
        <TwoColumnLayout imageDir={"images/ducks/Cowboy.png"}>
          <Gaming />
        </TwoColumnLayout>
        <Distribution />
        <FAQSection />
        <Team />
        <Partners />
        <Footer />
      </div>
    </ChakraProvider>
  );
};

export default App;
