import React from "react";

import { Image, VStack, Box } from "@chakra-ui/react";

const Gaming = () => (
  <Box>
    <VStack>
      <Image
        src="/images/text/gaming1.png"
        maxW="100%"
        maxH="500px"
        objectFit={"cover"}
      />
      <Image
        src="/images/text/gaming2.png"
        maxW="100%"
        maxH="400px"
        objectFit={"cover"}
      />
    </VStack>
  </Box>
);

export default Gaming;
