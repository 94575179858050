import { Flex, Image, Center, Text, Box } from "@chakra-ui/react";

const Distribution = () => (
  <Box>
    <Center>
      <Text color={"white"} fontSize={"4xl"} fontFamily={"TWeb"}>
        Duketz Distribution
      </Text>
    </Center>
    <Flex alignItems={"center"} justifyItems={"center"}>
      <Image width={"100%"} objectFit="contain" src="/images/distibution.png" />
    </Flex>
  </Box>
);

export default Distribution;
