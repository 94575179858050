import React from "react";

import { Image, VStack, Box } from "@chakra-ui/react";

const Utility = () => (
  <Box>
    <VStack>
      <Image maxH={"200px"} src="/images/text/utility1.png" fit={"contain"} />
      <Image maxH={"500px"} src="/images/text/utility2.png" fit={"contain"} />
    </VStack>
  </Box>
);

export default Utility;
