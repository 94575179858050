import React from "react";

import { Image, VStack, Box } from "@chakra-ui/react";

const Foresight = () => (
  <Box>
    <VStack>
      <Image
        src="/images/text/foresight.png"
        maxW="100%"
        maxH="400px"
        objectFit={"cover"}
      />
      <Image
        src="/images/text/foresight2.png"
        maxW="100%"
        maxH="400px"
        objectFit={"cover"}
      />
      <Image
        src="/images/text/foresight3.png"
        maxW="100%"
        maxH="400px"
        objectFit={"cover"}
      />
    </VStack>
  </Box>
);

export default Foresight;
