import { Box, Flex, Image, useColorModeValue } from "@chakra-ui/react";

const Footer = () => {
  const bgColor = useColorModeValue("purple.500", "purple.800");

  return (
    <Box bg={bgColor} py={4} px={2} color="white" opacity={0.8}>
      <Flex justifyContent="space-between" alignItems="center">
        <Image
          src="/images/logo-sm.png"
          alt="Logo"
          width={{ base: "150px", md: "300px" }}
          height={{ base: "30px", md: "50px" }}
        />
        <Image
          src="/images/copyright.png"
          alt="Copyright"
          width={{ base: "150px", md: "300px" }}
          height={{ base: "24px", md: "40px" }}
        />
      </Flex>
    </Box>
  );
};

export default Footer;
