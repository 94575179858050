//"start": "set PORT=3001&& react-scripts start",

import React from "react";
import {
  Box,
  Center,
  Image,
  Link,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";

const team = [
  {
    id: 1,
    image: "./images/team/Augie.png",
    title: "DuckGod",
    role: `"The beginning is the most important part of the work." -Plato`,
    description: `Creative director and graphic designer with over ten years of expertise, 
                  in charge of merging design and strategy. The project's talented and 
                  hardworking principal digital artist, responsible for the signature graphics on each avatar.`,
    twitterImg: "./images/team/dskgaming99.png",
    twitterLink: "https://twitter.com/CryptoDuketzNFT",
  },
  {
    id: 2,
    image: "./images/team/Kevin.png",
    title: "KingEider",
    role: `"NFTs & The Internet of value: Artistry meets digital reality. The Future of data monetization starts here." -Kevin Je`,
    description: `A Crypto Duketz lurking in the shadows. He is an expert in marketing, cryptocurrency, and nftz. 
                He creates strategies, unites and develops brand alliances, the storytelling vision and the marketing 
                roadmap, and does other things. The Glue keeping the community and team engaged.Simply ask him.`,
    twitterImg: "./images/team/bob_sanget.png",
    twitterLink: "https://twitter.com/bob_sanget",
  },
  {
    id: 3,
    image: "./images/team/BJ.png",
    title: "Overlord Stiffbeak",
    role: `"One machine can do the work of fifty ordinary men. No machine can do the the work of one extraordianary man." -Elbert Hubbard`,
    description: `He is well-known in the Crypto and NFT industry as the guardian of social media. He is a Pivot
                  Coach who facilitates transformation.`,
    twitterImg: "./images/team/Sleepoverpals.png",
    twitterLink: "https://twitter.com/Sleepoverpals",
  },
  {
    id: 4,
    image: "./images/team/JDee.png",
    title: "Overlord Stiffbeak",
    role: `"The word impossible has possible in it." -JDLA`,
    description: `He is well-known in the Crypto and NFT industry as the guardian of social media. He is a Pivot
                  Coach who facilitates transformation.`,
    twitterImg: "./images/team/Sleepoverpals.png",
    twitterLink: "https://twitter.com/JDEE2023",
  },
  {
    id: 5,
    image: "./images/team/Andres.png",
    title: "Black Scoter Duck",
    role: `"Quack" -A Duck`,
    description: `Web developer who is talented and seeks new challenges. This Duket is wise and most astute. 
                  A true genius at the forefront of technological advancement.`,
    twitterImg: "./images/team/CodenameBott.png",
    twitterLink: "https://twitter.com/CodenameBott",
  },
  {
    id: 6,
    image: "./images/team/Patel.png",
    title: "Sea-Guru",
    role: `"We know more about the solar system than we do about our own oceans." -Facts`,
    description: `Sea-Guru is a Sharp co-founder of Aware Ads and Oasis Ads Media Strategized Guru.`,
    twitterImg: "./images/team/yatharthpatel.png",
    twitterLink: "https://twitter.com/yatharthpatel",
  },

  {
    id: 7,
    image: "./images/team/Stone.png",
    title: "Stone",
    role: `"You are a reflection of how you think. Always stay positive and never say the word impossible." -Stone`,
    description: ``,
    twitterImg: "",
    twitterLink: " https://twitter.com/spacedog1111",
  },
];

const showTeam = (team) => {
  return (
    <Box key={team.id} align={"center"}>
      <Link href={team.twitterLink} isExternal>
        <Image
          objectFit={"fit"}
          boxSize="16rem"
          src={team.image}
          rounded={"full"}
          boxShadow={"xl"}
          _hover={{
            boxShadow: "2xl",
            transition: "all .3s ease",
            transform: "translateY(-3px)",
          }}
        />
      </Link>
      <Text
        maxW={"350px"}
        align={"center"}
        fontFamily={"Oswald"}
        fontSize={"lg"}
        color={"White"}
      >
        {team.role}
      </Text>
    </Box>
  );
};

const Team = () => {
  return (
    <Box id="Team">
      <Center>
        <Text color={"white"} fontSize={"4xl"} fontFamily={"TWeb"}>
          Duketz Team Members
        </Text>
      </Center>
      <Stack>
        <SimpleGrid
          justifyItems={"center"}
          columns={[1, 2, 2, 4]}
          spacing={"30px"}
        >
          {team.map((team) => showTeam(team))}
        </SimpleGrid>
      </Stack>
    </Box>
  );
};

export default Team;
