import React from "react";

import { Image, VStack, Box } from "@chakra-ui/react";

const LineUp = () => (
  <Box>
    <VStack>
      <Image
        src="/images/text/lineup1.png"
        maxW="100%"
        maxH="400px"
        objectFit={"cover"}
      />
      <Image
        src="/images/text/lineup2.png"
        maxW="100%"
        maxH="250px"
        objectFit={"cover"}
      />
      <Image
        src="/images/text/lineup3.png"
        maxW="100%"
        maxH="50px"
        objectFit={"cover"}
      />
    </VStack>
  </Box>
);

export default LineUp;
