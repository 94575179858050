import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Heading,
} from "@chakra-ui/react";

const FAQSection = () => {
  const faqs = [
    {
      question: "What is an NFT?",
      answer:
        "A non-fungible token is a digital certificate of authenticity, which functions like a property deed or receipt—it proves that ownership of a certain digital item is unique to one buyer. The NFT can represent anything: photography, animation, music, literature, and so on. The NFT’s proof of ownership can’t be altered or duplicated, but it can be traded or resold by its owner.",
    },
    {
      question: "How do I buy an NFT?",
      answer:
        "To purchase an NFT, simply select the product you are interested in buying. Depending on the currency being used, you will be prompted to pay for the NFT either via credit card or via your crypto wallet.",
    },
    {
      question: "What is a wallet and how do I get one?",
      answer:
        "Crypto wallets are where you store the cryptocurrencies and NFTs you own.",
    },
    {
      question: "How do I connect my wallet?",
      answer:
        "If you haven't already connected your wallet to your Crypto Duketz account, you'll be prompted to do so once you select a product you are interested in. Follow the instructions provided to connect. \n\nPlease note that the wallet extension must be enabled on the browser you are using to shop to connect your wallet. For example, you will be able to connect your wallet if you are shopping on Chrome on your desktop and the MetaMask extension is installed on Chrome on your desktop.",
    },
    {
      question: "What happens after I buy an NFT? / Where can I see my NFT?",
      answer:
        "After you purchase an NFT, Crypto Duketz will deliver it to your connected wallet within 7 days. You can view the NFTs you’ve purchased directly in your wallet or on a third party marketplace.",
    },
  ];

  return (
    <Box color={"white"} fontFamily={"TWeb"} padding={"5"}>
      <Heading
        as="h2"
        size="lg"
        mb={4}
        textAlign={"center"}
        fontFamily={"TWeb"}
      >
        FAQs
      </Heading>
      <Accordion allowMultiple>
        {faqs.map((faq, index) => (
          <AccordionItem key={index}>
            <AccordionButton>
              <Box flex="1" textAlign="left" fontSize={"xl"}>
                {faq.question}
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4} fontSize={"lg"}>
              {faq.answer}
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </Box>
  );
};

export default FAQSection;
