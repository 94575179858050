import { Center, Box, Flex, Image } from "@chakra-ui/react";

const TwoColumnLayout = ({ imageDir, children }) => (
  <Flex
    direction={{ base: "column", md: "row" }}
    paddingTop={"25"}
    bgRepeat="no-repeat"
    bgSize="cover"
  >
    <Box w={{ base: "100%", md: "50%" }} h={{ base: "50%", md: "100%" }}>
      <Image objectFit="cover" src={imageDir} />
    </Box>
    <Center w={{ base: "100%", md: "50%" }} h={{ base: "50%", md: "100%" }}>
      {children}
    </Center>
  </Flex>
);

export default TwoColumnLayout;
