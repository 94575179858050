import React from "react";

import { Image, VStack, Box } from "@chakra-ui/react";

const Store = () => (
  <Box>
    <VStack>
      <Image
        src="/images/text/store1.png"
        maxW="100%"
        maxH={{ base: "400px", md: "400px" }}
        objectFit={"cover"}
      />
      <Image
        src="/images/text/store2.png"
        maxW="100%"
        maxH={{ base: "100px", md: "100px" }}
        objectFit={"cover"}
      />
      <Image
        src="/images/text/store3.png"
        maxW="100%"
        maxH={{ base: "100px", md: "200px" }}
        objectFit={"cover"}
      />
    </VStack>
  </Box>
);

export default Store;
