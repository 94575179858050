//"start": "set PORT=3001&& react-scripts start",

import React from "react";
import {
  Box,
  Center,
  Image,
  Link,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";

const team = [
  {
    id: 1,
    image: "./images/partners/coc.png",
    twitterLink:
      "https://sites.google.com/view/communityofcommunities/homepage",
  },
  {
    id: 2,
    image: "./images/partners/jdla.png",
    twitterLink: "https://linktr.ee/JDLA2023",
  },
  {
    id: 3,
    image: "./images/partners/skull-of-tyger-nft.png",
    twitterLink: "https://linktr.ee/skulloftyger",
  },
  {
    id: 4,
    image: "./images/partners/defi-space.png",
    twitterLink: "https://linktr.ee/TrickyBuddha",
  },
  {
    id: 5,
    image: "./images/partners/linktree.png",
    twitterLink: "https://linktr.ee/crypto.duketz",
  },

  {
    id: 6,
    image: "./images/partners/aigaming.png",
    twitterLink: "https://www.youtube.com/channel/UCnCEeRYsZDwZzdrl4yR53yA",
  },
  {
    id: 7,
    image: "./images/partners/tiktoken.png",
    twitterLink: "https://technicallyweb3.com/",
  },
  {
    id: 8,
    image: "./images/partners/ccrypto.png",
    twitterLink: "https://canadiancryptocurrency.ca",
  },
  {
    id: 9,
    image: "./images/partners/takagari.png",
    twitterLink: "https://twitter.com/TakagariDonk",
  },
  {
    id: 10,
    image: "./images/partners/join.png",
    twitterLink: "https://discord.gg/kVzxPMVYbS",
  },
];

const showTeam = (team) => {
  return (
    <Box key={team.id} align={"center"}>
      <Link href={team.twitterLink} isExternal>
        <Image
          objectFit={"contain"}
          boxSize={"20rem"}
          src={team.image}
          boxShadow={"xl"}
          _hover={{
            boxShadow: "2xl",
            transition: "all .3s ease",
            transform: "translateY(-3px)",
          }}
        />
      </Link>
    </Box>
  );
};

const Partners = () => {
  return (
    <Box id="Team" paddingY={10}>
      <Center>
        <Text color={"white"} fontSize={"4xl"} fontFamily={"TWeb"}>
          Our Web 3 Partners
        </Text>
      </Center>
      <Stack>
        <SimpleGrid
          justifyItems={"center"}
          columns={[1, 2, 2, 4]}
          spacing={"30px"}
        >
          {team.map((team) => showTeam(team))}
        </SimpleGrid>
      </Stack>
    </Box>
  );
};

export default Partners;
