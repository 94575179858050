import { useState } from "react";
import {
  Box,
  Flex,
  Icon,
  IconButton,
  Link,
  useColorModeValue,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Image,
  Button,
} from "@chakra-ui/react";
import {
  FaDiscord,
  FaYoutube,
  FaInstagram,
  FaTwitter,
  FaTiktok,
  FaBars,
} from "react-icons/fa";

import "../App.css";

function Navbar() {
  const bgColor = useColorModeValue("purple.500", "purple.800");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleDrawerOpen = () => setIsDrawerOpen(true);
  const handleDrawerClose = () => setIsDrawerOpen(false);

  return (
    <Box
      position="fixed"
      top={2}
      left={{ base: 1, md: 5 }}
      right={{ base: 1, md: 5 }}
      bg={bgColor}
      rounded={"lg"}
      opacity={0.8}
      zIndex="sticky"
    >
      <Flex
        align="center"
        justify={{ base: "space-evenly", md: "space-between" }}
        px={1}
        py={2}
        mx="auto"
        maxW="7xl"
      >
        <Box display={{ base: "flex", md: "none" }}>
          <IconButton
            icon={<FaBars />}
            aria-label="Open navigation menu"
            display={{ base: "block", md: "none" }}
            onClick={handleDrawerOpen}
          />
        </Box>
        <Link
        //href="/"
        >
          <Image
            src="/images/quackshot.png"
            alt="Quackshot Logo"
            width={{ base: "5rem", md: "10rem" }}
            display={{ base: "none", md: "flex" }}
          />
        </Link>
        <Box display={{ base: "none", md: "flex" }} alignItems={"center"}>
          <Link
            href="https://discord.gg/kVzxPMVYbS"
            isExternal
            _hover={{ textDecoration: "none" }}
          >
            <Icon
              as={FaDiscord}
              mr={2}
              boxSize={5}
              color="white"
              _hover={{ opacity: 0.7 }}
            />
          </Link>
          <Link
            href="https://www.youtube.com/@cryptoduketznft"
            isExternal
            _hover={{ textDecoration: "none" }}
          >
            <Icon
              as={FaYoutube}
              mr={2}
              boxSize={5}
              color="white"
              _hover={{ opacity: 0.7 }}
            />
          </Link>
          <Link
            href="https://www.instagram.com/crypto.duketz/"
            isExternal
            _hover={{ textDecoration: "none" }}
          >
            <Icon
              as={FaInstagram}
              mr={2}
              boxSize={5}
              color="white"
              _hover={{ opacity: 0.7 }}
            />
          </Link>
          <Link
            href="https://twitter.com/CryptoDuketzNFT"
            isExternal
            _hover={{ textDecoration: "none" }}
          >
            <Icon
              as={FaTwitter}
              mr={2}
              boxSize={5}
              color="white"
              _hover={{ opacity: 0.7 }}
            />
          </Link>
          <Link
            href="https://www.tiktok.com/@cryptoduketznftz.sol"
            isExternal
            _hover={{ textDecoration: "none" }}
          >
            <Icon
              as={FaTiktok}
              mr={2}
              boxSize={5}
              color="white"
              _hover={{ opacity: 0.7 }}
            />
          </Link>
        </Box>

        <Box display={{ base: "flex", md: "flex" }} alignItems={"center"}>
          <Link
            //href="/merch-store"
            mr={4}
            fontWeight="medium"
            color="white"
            _hover={{ textDecoration: "none" }}
          >
            Merch/Store
          </Link>
          <Link
            ///href="/marketplace"
            mr={4}
            fontWeight="medium"
            color="white"
            _hover={{ textDecoration: "none" }}
          >
            Marketplace
          </Link>
          <Link href="https://mint.cryptoduketz.io" isExternal>
            <Button
              className="pulse"
              minWidth={"90px"}
              mr={4}
              fontWeight="medium"
              color={"white"}
              bgGradient="linear(to-r, purple.600, pink.400)"
              _hover={{ textDecoration: "none" }}
            >
              Mint
            </Button>
          </Link>
        </Box>
        <Link href="https://linktr.ee/crypto.duketz">
          <Image
            src="/images/ducketz_logo.png"
            alt="Duckets Logo"
            width={{ base: "5rem", md: "12rem" }}
          />
        </Link>
        <Drawer
          placement="right"
          onClose={handleDrawerClose}
          isOpen={isDrawerOpen}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Join the Pond</DrawerHeader>
            <DrawerBody>
              <Box>
                <Link
                  //href="/merch-store"
                  mr={4}
                  mb={4}
                  fontWeight="medium"
                  color="white"
                  _hover={{ textDecoration: "none" }}
                >
                  Merch/Store
                </Link>
                <Link
                  //href="/marketplace"
                  mr={4}
                  mb={4}
                  fontWeight="medium"
                  color="white"
                  _hover={{ textDecoration: "none" }}
                >
                  Marketplace
                </Link>
                <Link
                  //href="/select-wallet"
                  mr={4}
                  mb={4}
                  fontWeight="medium"
                  color="white"
                  _hover={{ textDecoration: "none" }}
                >
                  Select Wallet
                </Link>
              </Box>
              <Box>
                <Link
                  href="https://discord.gg/kVzxPMVYbS"
                  isExternal
                  mr={4}
                  mb={4}
                  _hover={{ textDecoration: "none" }}
                >
                  <Icon
                    as={FaDiscord}
                    mr={2}
                    boxSize={5}
                    color="black"
                    _hover={{ opacity: 0.7 }}
                  />
                </Link>
                <Link
                  href="https://www.youtube.com/@cryptoduketznft"
                  isExternal
                  mr={4}
                  mb={4}
                  _hover={{ textDecoration: "none" }}
                >
                  <Icon
                    as={FaYoutube}
                    mr={2}
                    boxSize={5}
                    color="black"
                    _hover={{ opacity: 0.7 }}
                  />
                </Link>
                <Link
                  href="https://www.instagram.com/crypto.duketz/"
                  isExternal
                  mr={4}
                  mb={4}
                  _hover={{ textDecoration: "none" }}
                >
                  <Icon
                    as={FaInstagram}
                    mr={2}
                    boxSize={5}
                    color="black"
                    _hover={{ opacity: 0.7 }}
                  />
                </Link>
                <Link
                  href="https://twitter.com/CryptoDuketzNFT"
                  isExternal
                  mr={4}
                  mb={4}
                  _hover={{ textDecoration: "none" }}
                >
                  <Icon
                    as={FaTwitter}
                    mr={2}
                    boxSize={5}
                    color="black"
                    _hover={{ opacity: 0.7 }}
                  />
                </Link>
                <Link
                  href="https://www.tiktok.com/@cryptoduketznftz.sol"
                  isExternal
                  mb={4}
                  _hover={{ textDecoration: "none" }}
                >
                  <Icon
                    as={FaTiktok}
                    mr={2}
                    boxSize={5}
                    color="black"
                    _hover={{ opacity: 0.7 }}
                  />
                </Link>
              </Box>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Flex>
    </Box>
  );
}

export default Navbar;
